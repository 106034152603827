import Alpine from 'alpinejs';
import Modal from 'bootstrap/js/dist/modal';
import Offcanvas from 'bootstrap/js/dist/offcanvas';

(() => {
    document.addEventListener('alpine:init', () => {
        Alpine.data('Newsletter', () => ({
            subscribe(event) {
                event.preventDefault();

                const form = this.$refs.newsletterForm;
                const emailInput = this.$refs.emailInput;
                const validationErrorElement = this.$refs.validationError;
                const legalWarningElement = this.$refs.legalWarning;
                const legalWarningErrorElement = this.$refs.legalWarningError;
                const submitButton = this.$refs.submitButton;

                emailInput.classList.remove('border-rose-700', 'bg-rose-50');
                legalWarningErrorElement.classList.add('d-none');
                validationErrorElement.classList.add('d-none');

                if (!legalWarningElement.checked) {
                    legalWarningErrorElement.classList.remove('d-none');
                    return;
                }

                submitButton.classList.add('loading');

                fetch(form.dataset.action, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(Object.fromEntries(new FormData(form))),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            if (window.matchMedia('screen and (min-width:992px)').matches) {
                                const modalComponent = new Modal('#success-modal');
                                modalComponent.show();
                            } else {
                                const offcanvasComponent = new Offcanvas('#success-offcanvas');
                                offcanvasComponent.show();
                            }

                            document.dispatchEvent(new CustomEvent('Newsletter_Subscribe'));
                        } else {
                            let errorHtml = '';

                            Object.values(JSON.parse(data.errors)).forEach((error) => {
                                errorHtml += `<p>${error}</p>`;
                            });

                            emailInput.classList.add('border-rose-700', 'bg-rose-50');
                            validationErrorElement.innerHTML = errorHtml;
                            validationErrorElement.classList.remove('d-none');

                            document.dispatchEvent(new CustomEvent('Newsletter_Subscribe_Error'));
                        }

                        submitButton.classList.remove('loading');
                    });
            },
        }));
    });
})();
